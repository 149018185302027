import React, { useState } from "react";
import "../Styles/Start.css";
import IconButton from "@mui/material/IconButton";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import Video from "../Components/Video";

const VideoPlayer = () => {
  const [videoPopup, setVideoPopup] = useState(false);

  return (
    <div id="start" className="videoPlayer">
      <div className="videoBackground">
        <div className="museumTitle">KÖKARS KONSTMUSEUM</div>
        <div className="museumInfo">
          Ett projekt att förverkliga ett innovativt konstaktivitetshus på kökar
        </div>
        <IconButton
          className="videoButton"
          size="small"
          onClick={() => setVideoPopup(true)}
        >
          <PlayCircleOutlineRoundedIcon
            style={{ fontSize: 100, color: "white" }}
          />
        </IconButton>
      </div>
      <Video
        trigger={videoPopup}
        setVideoPopup={setVideoPopup}
        videoSrc="/Videos/001.MP4"
      />
    </div>
  );
};

export default VideoPlayer;
