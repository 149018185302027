import React from "react";
import "../Styles/Frontpage.css";
import Architecture from "../Components/Architecture";
import History from "../Components/History";
import Finansing from "../Components/Finansing";
import Payment from "../Components/Payment";
import VideoPlayer from "../Components/VideoPlayer";

const Frontpage = () => {
  return (
    <div>
      <VideoPlayer />
      <History />
      <Architecture />
      <Finansing />
      <Payment />
    </div>
  );
};

export default Frontpage;
