import React from "react";
import "../Styles/SponsorBox.css";
import { TfiIdBadge } from "react-icons/tfi";

const LargeSponsorship = () => {
  return (
    <div className="sponsoring">
      <div className="topOfBox">
        <div className="image">
          <TfiIdBadge style={{ height: 120, width: 100, paddingRight: 20 }} />
        </div>
        <div className="textArea">
          <div className="pillarBoxTitle">STÖRRE SPONSRING</div>
          <div className="pillarBoxInfo">
            <p>
              Ett tredje alternativ är större summa sponsring. Namn kommer
              skrivas fram på en egen mässingsplakett i olika storlekar inne i
              huset:
            </p>
          </div>
        </div>
      </div>
      <div className="sponsorOptions">
        <div className="optionBox">
          <div className="sponsorInfo">Brons</div>
          <div className="sponsorCost">1 500€</div>
        </div>
        <div className="optionBox">
          <div className="sponsorInfo">Silver</div>
          <div className="sponsorCost">5 000€</div>
        </div>
        <div className="optionBox">
          <div className="sponsorInfo">Guld</div>
          <div className="sponsorCost">10 000€</div>
        </div>
        <div className="optionBox">
          <div className="sponsorInfo">Platina</div>
          <div className="sponsorCost">15 000€</div>
        </div>
      </div>
      <div className="endPart"></div>
    </div>
  );
};

export default LargeSponsorship;
