import React from "react";
import "../Styles/MembershipBox.css";

const Membership = (props) => {
  const listItems = [];
  Object.values(props.info).forEach((e) => {
    listItems.push(<p>- {e}</p>);
  });

  return (
    <div className="box">
      <div className="boxTitle" style={{ color: props.color }}>
        {props.title}
      </div>
      <div className="cost">
        <p>{props.cost} €</p>
      </div>
      <div className="boxInfo">{listItems}</div>
    </div>
  );
};

export default Membership;
