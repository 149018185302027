import React from "react";
import "../Styles/Video.css";
import ReactPlayer from "react-player";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import myVideo from "../Videos/Video.mp4";

const Video = (props) => {
  return props.trigger ? (
    <div className="video">
      <div className="video-inner">
        <IconButton
          className="videoButton"
          size="small"
          onClick={() => props.setVideoPopup(false)}
        >
          <CloseRoundedIcon style={{ fontSize: 30, color: "white" }} />
        </IconButton>
        <ReactPlayer
          url="https://youtu.be/Qmi9BZz6muk"
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    </div>
  ) : (
    ""
  );
};

export default Video;
// url="https://youtu.be/Qmi9BZz6muk"
