export const MembershipInfo = {
  supportMembership: {
    title: "Stödmedlemskap",
    cost: 60,
    titleColor: "white",
    info: {
      1: "Betala in och bli medlem i Kökars Hembygdsförening ett år och betala en utökad medlemsavgift á 60 euro.",
      2: "10 euro motsvarar medlemsavgiften (du registreras som medlem) och 50 euro går direkt till projektet. Inbetalning via bankkonto, se 'Hur du betalar'. Skicka även namn och e-postadress.",
    },
  },
};
