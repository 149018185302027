import React from "react";
import "../Styles/SponsorBox.css";
import pillar from "../Images/column.svg";

const Pillar = () => {
  return (
    <div className="sponsoring">
      <div className="topOfBox">
        <div className="image">
          <img
            src={pillar}
            alt="Ikon av en pelare"
            style={{ height: 120, width: 120 }}
          ></img>
        </div>
        <div className="textArea">
          <div className="pillarBoxTitle">SPONSRA EN PELARE</div>
          <div className="pillarBoxInfo">
            <p>
              Husets exteriör kommer bestå av ett flertal pelare som samspelar
              med de omgivande alarna. Det är möjligt att köpa hel eller del av
              pelare.
            </p>
            <p>
              Vid köp kommer ditt namn om så önskas skrivas fram på en gemensam
              mässingsplakett som fästs på respektive pelare.
            </p>
          </div>
        </div>
      </div>
      <div className="sponsorOptions">
        <div className="optionBox">
          <div className="sponsorInfo">Kvarts pelare</div>
          <div className="sponsorCost">625€</div>
        </div>
        <div className="optionBox">
          <div className="sponsorInfo">Halv pelare</div>
          <div className="sponsorCost">1 250€</div>
        </div>
        <div className="optionBox">
          <div className="sponsorInfo">Hel pelare</div>
          <div className="sponsorCost">2 500€</div>
        </div>
      </div>
      <div className="endPart"></div>
    </div>
  );
};

export default Pillar;
