import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import "../Styles/Navbar.css";
import { FiMenu } from "react-icons/fi";
import logo from "../Images/logo.svg";

export const Navbar = () => {
  const [expandNavbar, setExpandNavbar] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setExpandNavbar(false);
  }, [location]);

  return (
    <>
      <nav className="navbar" id={expandNavbar ? "open" : "close"}>
        <div className="toggleButton">
          <button
            onClick={() => {
              setExpandNavbar((prev) => !prev);
            }}
          >
            {" "}
            <FiMenu />{" "}
          </button>
        </div>
        <div className="navbar-container">
          <div className="navbarLinks">
            <Link to="#start">Start</Link>
            <Link to="#design">Design</Link>
            <img src={logo} alt="logo" style={{ height: 80, width: 80 }}></img>
            <Link to="#finansiering">Finansiering</Link>
            <Link to="#sponsorer">Sponsorer</Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
