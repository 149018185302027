import React from "react";
import sketch from "../Images/sketch.svg";
import { GiGreekTemple } from "react-icons/gi";

const Architecture = () => {
  return (
    <div id="design" className="architecture">
      <div className="sectionTitle">ARKITEKTUR</div>
      <div className="wrapper">
        <div className="paragraph">
          <div className="paragraphTitle">
            Inspirerat av Pallas Athenas Parthenon
          </div>
          <div className="paragraphText">
            <p>
              Arkitektfirman OPAFORM med säte i Bergen, Norge har under
              ledningen av arkitekt Marina Bauer utformat och ritat en ”ikonisk”
              byggnad som rum för kökarkonsten. Huset är inspirerat av Pallas
              Athenas palats på Parthenon och anpassat till miljön på
              hembygdsgårdens område i en ”pelarliknande” aldunge. OPAFORM är
              kända för sina miljöanpassade hus byggda i miljövänligt material.
              Alla formaliteter är beslutade som exempelvis kommunens bygglov.
            </p>
          </div>
        </div>
        <div className="image">
          <GiGreekTemple />
        </div>
      </div>
      <div className="wrapper">
        <div className="image">
          <img
            src={sketch}
            alt="Ikon av ritning"
            style={{ height: 220, width: 220 }}
          ></img>
        </div>
        <div className="paragraph">
          <div className="paragraphTitle">
            Ritningar av Rolf Eriksson och Jan Gouideo
          </div>
          <div className="paragraphText">
            <p>
              Det arkitektoniska stödet för att visa virtuell konst är helt
              avgörande för husets funktionalitet. Det har framställts ritningar
              för inredningen avseende kraft, ljus, ljud och bild. Ritningarna
              har utformats av Rolf Eriksson och Jan Gouideo som tillsammans har
              lång erfarenhet av olika projekt med teaterljus, teknik kopplat
              till ljus och ljud i utställningshallar samt arbete med museer.
            </p>
            <p>
              Hela förprojekteringen är därmed avslutad och klar. Nästa steg är
              att söka finansiering för detaljprojektet med arbetsritningar,
              anbudsbeskrivningar med material- och produktlistor, upphandling
              och byggstart.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Architecture;
