import React from "react";
import donation from "../Images/donation.svg";
import Membership from "./Membership";
import { MembershipInfo } from "../Data/MembershipInfo";
import Pillar from "./Pillar";
import LargeSponsorship from "./LargeSponsorship";

const Finansing = () => {
  var membershipPlans = [];
  Object.values(MembershipInfo).forEach((e) => {
    membershipPlans.push(
      <Membership
        title={e.title}
        cost={e.cost}
        color={e.titleColor}
        info={e.info}
      />
    );
  });

  return (
    <div id="finansiering" className="finansing">
      <div className="sectionTitle">FINANSIERING</div>
      <div className="wrapper">
        <div className="paragraph">
          <div className="paragraphTitle">
            Det finns flera sätt att hjälpa oss att förvekliga konstmuseet på
            Kökar
          </div>
          <div className="paragraphText">
            <p>
              I vår strävan att förverkliga ett konstmuseum på Kökar är vi
              beroende av ekonomiskt stöd. Vi har kommit långt med förarbetet
              och är nu klara för att börja bygga huset! Projektet drivs inom
              ramen för Kökars Hembygdsförening och projektets främsta resurser
              består av ideella krafter, bred förankring, stora visioner,
              know-how och en hel del hårt arbete. Med dessa resurser kommer man
              långt! Dessvärre är den krassa verkligheten sådan att det även
              behövs en hel del pengar.
            </p>
            <p>
              En penninggåva oavsett storlek gör stor skillnad för projektet,
              för Hembygdsföreningen och kulturen samt näringslivet på Kökar!
            </p>
          </div>
        </div>
        <div className="image">
          <img
            src={donation}
            alt="Ikon av händer som håller ett hjärta"
            style={{ height: 120, width: 120 }}
          ></img>
        </div>
      </div>
      <div className="membershipBox">
        <div className="boxWrapper">{membershipPlans}</div>
      </div>
      <div className="sponsorPillar">
        <div className="pillarWrapper">
          <Pillar />
        </div>
      </div>
      <div className="sponsorPillar">
        <div className="pillarWrapper">
          <LargeSponsorship />
        </div>
      </div>
    </div>
  );
};

export default Finansing;
