import React from "react";
import "../Styles/Payment.css";

const Payment = () => {
  return (
    <div id="payment" className="payment">
      <div className="paymentWrapper">
        <div className="paymentTitle">Hur du betalar</div>
        <div className="paymentInstructions">
          <p>Konto för inbetalning FI4666010001205715</p>
          <p>
            Vid all inbetalning skicka E-post för registrering (självklart kan
            du sponsra anonymt!). <br />
            E-post: kokarhembygd@gmail.com bifoga följande uppgifter:
            <br />
            * Namn
            <br />
            * egen E-postadress
            <br />
            * belopp och typ av sponsring
            <br />
          </p>
        </div>
        <div className="paymentTitle">Exempel</div>
        <div className="paymentInstructions">
          <p>
            E-postmeddelande: “Jan Jansson, jansson@gmail.com, Stödmedlemskap
            60€”
          </p>
        </div>
      </div>
    </div>
  );
};

export default Payment;
