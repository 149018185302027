import React from "react";
import "../Styles/History.css";
import landscapeImage from "../Images/landscape.svg";

const History = () => {
  return (
    <div className="history">
      <div className="sectionTitle">HISTORIA</div>
      <div className="wrapper">
        <div className="paragraph">
          <div className="paragraphTitle">Kökars Kulturliv</div>
          <div className="paragraphText">
            <p>
              Kökars natur och kultur är i sig en enastående sevärdhet som
              lockat och lockar många besökare. platsens attraktion och känsla
              av det unika har under lång tid, utöver turister, även lockat
              konstnärer och forskare.
            </p>
            <p>
              Redan under 1800-talet sökte sig många konstnärer till Kökar som
              ”exotisk” plats med fokus på landskapsmåleri och folklivet förr.
              Här märks nu berömda konstnärer som Karl Emanuel Jansson, Alvar
              Cawén, Marcus Collin osv. Konstnärstraditionen från 1800-talet
              kopplat till platsens betydelse har levt vidare i stort sett
              obrutet fram till idag. Och det går att notera att allt fler
              nutidskonstnärer söker sig till Kökar. Märkbart är också att
              nutidskonstens former spelar en alltmer väsentlig roll i Kökars
              kulturliv.
            </p>
            <p>
              Kökar och Kökars hembygdsförening saknar dock en arena för såväl
              den äldre konsten som den moderna. Under fyra år, sedan 2018 har
              hembygdsföreningen och en arbetsgrupp arbetat för att skapa just
              en sådan arena i form av ett unikt miljöanpassat hus där konsten
              från förr samt konst här och nu kan upplevas.
            </p>
          </div>
        </div>
        <div className="image">
          <img
            src={landscapeImage}
            alt="Landskapsikon"
            style={{ height: 260, width: 260 }}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default History;
